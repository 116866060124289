<template>
  <div
    :style="`width: calc(${blockSize}% - 1em)`"
    class="blockItem"
    v-bind:class="{ 'blockItem-border': border }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BlockItem",
  props: {
    size: String,
    border: Boolean,
  },
  data() {
    return {
      blockSize: Number,
    };
  },
  created() {
    const self = this;

    let sizes = self.size.split("-");
    let blockSize = (sizes[0] * 100) / sizes[1];
    this.blockSize = blockSize;
  },
};
</script>

<style scoped>
.blockItem {
  position: relative;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.blockItem-border {
  margin-top: 1.5em;
  padding: 0.5em;

  padding-top: 2em;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}
</style>