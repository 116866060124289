<template>
  <div class="blockColum">
    <div v-if="eventName" class="btnAdd" @click="sendEvent()">
      <CIcon name="cil-plus" />
    </div>
    <div v-if="title" class="columTitle">{{ title }}</div>

    <div class="blockColumnContent">
      <!-- <div><CIcon name="cil-remove" />sdfsdfsdf</div> -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockColumn",
  props: {
    title: String,
    eventName: String,
  },
  computed: {},
  methods: {
    sendEvent() {
      this.$emit(this.eventName);
    },
  },
};
</script>

<style scoped>
.blockColum {
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  font-size: 16px;

  position: relative;
}
.columTitle {
  display: block;
  width: 100%;
  margin-bottom: 1em;
}
.blockColumnContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.btnAdd {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 11%);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>