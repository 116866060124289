<template>
  <div class="blockInfo">
    <div class="bockTitle">{{ title }}</div>
    <select
      :name="title"
      :selected="selected"
      v-model="valueSelected"
      class="blockSelect"
      :disabled="disabled"
      v-on:input="updateValue($event.target.value)"
      @keydown="$emit('input-change', true)"
      v-on:change="changeValue($event.target.value)"
    >
      <option
        v-for="item in values"
        :value="item._key ? item._key : item"
        :key="item._key ? item.value : item"
      >
        {{ item._key ? item.value : item }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "BlockInfoSelect",
  props: {
    title: String,
    selected: String || Number,
    values: Array,
    disabled: Boolean,
  },
  computed: {
    valueSelected: {
      get: function () {
        return this.selected;
      },
      set: function (value) {
        return value;
      },
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    changeValue(value) {
      this.$emit("change-value", value);
    },
  },
  created(){
    this.$emit('input', this.selected);
  }
};
</script>


<style scoped>
.blockInfo {
  padding-top: 0.5em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
}
.bockTitle {
  width: 50%;
}
.blockSelect {
  width: 100%;
  margin: 0;
  padding: 0.5em;
  cursor: pointer;
}
.blockSelect,
.blockSelect:active,
.blockSelect:hover,
.blockSelect:focus {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 11%);
  outline: none;
  border: 0;
  border-radius: 5px;
}
</style>